import { OtherChannelList } from '@base/lib/constant'
import { setItem, getItem, removeItem } from '@base/lib/storage-handler'
import { ChannelEnum, OtherChannel } from '@base/enum/channel'
import { getUuid } from './utils'
import { getUrlChannel, getLocalChannel, getChannel } from './checkChannel'
import { verifyChannelToken } from '@base/api/channel'

export const isOtherChannel = (channel = OtherChannel.LINKAJA) => {
  const urlChannel = getUrlChannel()?.toUpperCase()
  const localChannel = getLocalChannel()
  return urlChannel?.includes(channel) || localChannel === channel
}

export const isOtherChannels = () => {
  return getUrlChannel()?.toUpperCase() !== ChannelEnum.H5
}

export const isFromChannel = () => {
  return OtherChannelList.includes(getUrlChannel()?.toUpperCase() as OtherChannel)
}

export const getParams = (name: string) => {
  const query = new URLSearchParams(location.search)
  return query.get(name)
}

export const checkDefaultChannel = (channel: string) => {
  if (channel !== ChannelEnum.H5) return
  removeItem('capp_phone_num')
  removeItem('channelToken')
  removeItem('loginStatus')
}

/**/
const judgeClient = () => {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android
  // TODO: iOS判断不准确
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // iOS
  if (isAndroid) {
    return 'WebAndroid'
  } else if (isIOS) {
    return 'WebiOS'
  } else {
    return 'PC'
  }
}

export const getXUserAgent = (currentLang: string) => {
  return `Pintar-ID-Cash (${judgeClient()};;;${currentLang}) uuid/${getUuid()} version/0.1.0`
}

export const getMarketChannel = () => {
  if (getItem('original_URL')) {
    return new URL(getItem('original_URL') || '')?.searchParams?.get('utm_source') || ''
  }
  return ''
}

/* MITRA*/
export const isMitraChannel = () => {
  return getLocalChannel() === OtherChannel.MITRA
}

export const isIohChannel = () => {
  return getLocalChannel() === OtherChannel.BIMA3 || getLocalChannel() === OtherChannel.MYIM3
}

export const isGopayChannel = () => {
  return getLocalChannel() === OtherChannel.GOPAY
}

export const isTokopediaChannel = () => {
  return getChannel() === OtherChannel.TOKOPEDIA
}

/* Channels that can use dynamic forms */
export const useDynamicForm = () => {
  const channelList = [OtherChannel.BIMA3, OtherChannel.MYIM3]
  return channelList.includes(getLocalChannel() as OtherChannel)
}

export const verifyChannel = async (newToken = '') => {
  console.log('******: ', 'verifyChannel')
  try {
    await verifyChannelToken({ token: newToken })
    setItem('loginStatus', 'true')
    console.log('******: ', 'loginStatus', 'true')
  } catch (error) {
    setItem('loginStatus', 'false')
    console.log('******: ', 'loginStatus', 'false')
  } finally {
    setItem('channelToken', newToken)
  }
}

// This channel is only used for comparison
export const saveComparedChannel = () => {
  setItem('comparedChannel', getChannel())
}

export const removeComparedChannel = () => {
  removeItem('comparedChannel')
}
