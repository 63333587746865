export enum OtherChannel {
  LINKAJA = 'LINKAJA',
  OTTOH5 = 'OTTOH5',
  MITRA = 'MITRABUKALAPAK',
  MYIM3 = 'MYIM3',
  BIMA3 = 'BIMA3',
  TOKOPEDIA = 'TOPED',
  MOXA = 'MOXA',
  GOPAY = 'GOPAY',
}

/** chanel type */
export enum ChannelEnum {
  H5 = 'H5',
  TCASH = 'TCASH',
  OTTO = 'OTTO',
}
